<template>
  <div style="display: contents">
    <a-modal
      v-bind="modalProps"
      :visible="componentVisible"
      :title="title"
      :width="width"
      :maskClosable="maskClosable"
      @cancel="cancelModal"
      :confirmLoading="confirmLoading"
      @ok="handleFinish"
    >
      <a-form :form="form" :layout="layout" :labelCol="labelCol" :wrapperCol="wrapperCol" selfUpdate>
        <slot name="form" :values="values" />
      </a-form>
    </a-modal>
    <div @click="showModal" style="display: contents">
      <slot name="trigger" />
    </div>
  </div>
</template>
<script>
export default {
  model: {
    prop: 'visible',
    event: 'visibleChange',
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    visible: {
      type: Boolean,
      default: undefined,
    },
    width: {
      type: Number,
      default: 600,
    },
    formRef: {
      type: String,
      default: undefined,
    },
    layout: {
      type: String,
    },
    labelCol: {
      type: Object,
      default: () => ({ span: 6 }),
    },
    maskClosable: {
      type: Boolean,
      default: false,
    },
    wrapperCol: {
      type: Object,
      default: () => ({ span: 16 }),
    },
    modalProps: {
      type: Object,
      default: () => ({ okText: '提交' }),
    },
    onFinish: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      innerVisible: false,
      confirmLoading: false,
      values: {},
      form: this.$form.createForm(this, {
        name: 'modalForm',
        onFieldsChange: (e) => {
          this.values = e.getFieldsValue();
        },
      }),
    };
  },
  watch: {
    form: {
      immediate: true,
      handler(val) {
        this.$emit('getForm', val);
        if (val && this.formRef) {
          this.$parent.$refs[this.formRef] = val;
        }
      },
    },
    componentVisible(val) {
      if (!val) {
        this.form.resetFields();
      }
    },
  },
  computed: {
    // modal最终的visible,
    // 外部传递则用外部的
    componentVisible() {
      return this.visible ?? this.innerVisible;
    },
  },

  methods: {
    showModal() {
      this.innerVisible = true;
      this.$emit('visibleChange', true);
    },
    cancelModal() {
      this.innerVisible = false;
      this.form.resetFields();
      this.$emit('visibleChange', false);
      this.$emit('cancel');
    },
    async handleFinish() {
      await new Promise((resolve, reject) => {
        this.form.validateFields((errors) => {
          errors ? reject() : resolve();
        });
      });
      this.confirmLoading = true;

      const closble = await this.onFinish(this.form.getFieldsValue());

      if (closble) {
        this.cancelModal();
      }
      this.confirmLoading = false;
    },
  },
};
</script>
